<template>
  <div class="new-script-managment">
    <div class="btn-two">
      <el-button class="getBackBtn" type="primary" @click="window = true">添加脚本类型</el-button>
    </div>
    <div class="no-data-icon" v-if="array.length == 0">
      <img src="../../../assets/image/no_data.png" alt="" style="margin-bottom: 10px; width: 500px" />
      <span class="no-data-font">暂无数据</span>
    </div>
    <div class="window-open">
      <el-dialog :visible.sync="window" width="30%" @close="window = false">
        <template slot="title">
          <div>
            <span class="header-tag-box"></span>
            添加脚本类型
          </div>
        </template>
        <!-- 脚本类型 -->
        <div class="script-openation">
          <span style="margin-right: 10px">脚本类型</span>
          <el-select v-model="scriptCategory" placeholder="请选择" @change="showBagListData(scriptCategory)">
            <el-option v-for="item in scriptOptions" :key="item.id" :label="item.label" :value="item.id"> </el-option>
          </el-select>
        </div>
        <!-- 话术，行业分类标题 -->
        <div class="script-more" style="margin-top: 20px">
          <span style="margin-right: 10px">{{ scriptCategory == 3 ? "福袋分类" : "" }}</span>
          <!-- 福袋下拉框 -->
          <el-select v-model="bagItem" placeholder="请选择" v-if="scriptCategory == 3" @change="getBagItemShowbagList(bagItem)">
            <el-option v-for="item in scriptCategory == 3 ? bagListCategory : ''" :key="item.id" :label="item.name" :value="item.category_id"> </el-option>
          </el-select>
          <!-- 选择福袋 -->
          <div class="shop-table" v-if="scriptCategory == 3">
            <el-checkbox-group v-model="bagListChecked">
              <el-checkbox :label="item.id" v-for="(item, index) in bagCheckList" :key="index" class="shop-check">{{ item.bag_name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeWindow">取 消</el-button>
          <el-button type="primary" @click="addDataFormTable">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div v-for="(item, index) in array" :key="index">
      <!-- 话术 -->
      <div class="script-container" v-if="item.type == 1">
        <div class="script-header">
          <div class="script-header-item" style="width: 14%; padding-left: 39px">脚本类型</div>
          <div class="script-header-item" style="width: 17%">时间</div>
          <div class="script-header-item" style="width: 24%">脚本内容</div>
          <div class="script-header-item" style="width: 13%">关键词</div>
          <div class="script-header-item" style="width: 16%">错误关键词</div>
          <div class="script-header-item" style="width: 17%">观众流失率</div>
          <div class="script-header-item" style="width: 6%">操作</div>
        </div>
        <div class="script-bory-box">
          <el-scrollbar style="height: 100%">
            <div v-for="(subitem, subindex) in item.list" :key="subindex">
              <div class="script-body">
                <div class="script-body-item" style="width: 14%; border-right: 3px solid #f7f8fc; font-weight: bold; padding-left: 39px; box-sizing: border-box; height: auto">
                  {{ subindex === 0 ? "话术" : "" }}
                </div>

                <div class="script-body-item" style="width: 17%; margin-right: 15px">
                  <el-input-number :controls="false" :precision="0" style="width: 80px; margin-left: 9px" :min="0" v-model="subitem.minute" :ref="`speech_minute${index}_${subindex}`"></el-input-number>
                  <span style="margin-left: 5px">分</span>
                  <el-input-number :controls="false" :precision="0" style="width: 80px; margin-left: 5px" :min="0" :max="59" v-model="subitem.seconds" :ref="`speech_seconds${index}_${subindex}`" @blur="checkedStatus(index, item, subindex)"></el-input-number>
                  <span style="margin-left: 5px">秒</span>
                </div>
                <div class="script-body-item" style="width: 24%">
                  <el-input type="textarea" style="width: 300px" autosize :rows="2" v-model="subitem.script_content" :ref="`speech_content${index}_${subindex}`"></el-input>
                </div>
                <div class="script-body-item" style="width: 15%">
                  <el-input style="width: 120px; margin-left: 5px" v-model="subitem.key_word" :ref="`speech_key${index}_${subindex}`"></el-input>
                </div>
                <div class="script-body-item" style="width: 14%">
                  <el-input style="width: 120px; margin-left: 5px" v-model="subitem.miss_key_word" :ref="`speech_miss${index}_${subindex}`"></el-input>
                </div>
                <div class="script-body-item" style="width: 14%; padding-left: 24px">
                  <el-input-number :min="0" :max="10" :controls="false" :precision="2" v-model="subitem.turnover_rate" style="width: 120px" :ref="`speech_rate${index}_${subindex}`"></el-input-number>
                  <span style="margin-left: 2px">%</span>
                </div>
                <div class="script-body-item" style="width: 9%; margin-left: 19px">
                  <span style="color: blue; cursor: pointer; margin-right: 10px" @click="addScriptItem(subitem.id, index)">添加实时弹幕</span>
                  <span style="color: red; cursor: pointer" @click="delScript(index, subindex)">删除</span>
                </div>
              </div>
              <div class="script-body" v-for="(subItems, subIndexs) in subitem.barrage" :key="subIndexs">
                <div class="script-body-icon" style="width: 14%; float: right">
                  <div class="lrline"></div>
                </div>
                <div class="script-body-item" style="90%">
                  <el-input placeholder="弹幕内容" v-model="subItems.content" style="margin-right: 29px" :ref="`children_content${subindex}_${subIndexs}`"> </el-input>
                  <el-input-number :controls="false" :precision="0" style="width: 140px" placeholder="出现次数" v-model="subItems.number" :ref="`children_number${subindex}_${subIndexs}`"></el-input-number>
                  <span style="margin-left: 9px">次</span>
                </div>
                <div
                  style="
                    margin-left:130px
                    text-align: right;
                    line-height: 80px;
                    color: red;
                    cursor: pointer;
                  "
                  @click="delStartSubItem(subindex, index, subIndexs)"
                >
                  删除
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="script-footer">
          <el-button style="color: #4b45ff; border: 1px solid #4b45ff" @click="delScriptBox(index)">删除</el-button>
          <el-button style="color: #4b45ff; border: 1px solid #4b45ff" @click="addStartScript(index)">添加脚本话术</el-button>
        </div>
      </div>

      <!-- 商品 -->
      <div class="shop-num" style="margin-top: 20px" v-if="item.type == 2">
        <div class="script-container">
          <div class="script-header">
            <div class="script-header-item" style="width: 7%; padding-left: 39px">脚本类型</div>
            <div class="script-header-item" style="width: 34%">时间</div>
            <div class="script-header-item" style="width: 34%">选品数量</div>
            <div class="script-header-item" style="width: 25%">操作</div>
          </div>
          <div class="script-body">
            <div class="script-body-item" style="width: 7%; border-right: 3px solid #f7f8fc; font-weight: bold; padding-left: 39px; box-sizing: border-box">商品</div>
            <div class="script-body-item" style="width: 34%">
              <el-input-number style="width: 50px; margin-left: 39px" v-model="item.list.time1" :ref="`goods_start${index}`" :max="60" :min="0" :controls="false"></el-input-number>
              <span style="margin-left: 5px">分</span>
              <el-input-number style="width: 50px; margin-left: 5px" v-model="item.list.time2" :ref="`goods_end${index}`" @blur="checkedStatus(index, item)" :max="59" :min="0" :controls="false"> </el-input-number>
              <span style="margin-left: 5px">秒 ~</span>
              <el-input disabled style="width: 50px; margin-left: 5px" v-model="item.list.time3"></el-input>
              <span style="margin-left: 5px">分</span>
              <el-input disabled style="width: 50px; margin-left: 5px" v-model="item.list.time4"></el-input>
              <span style="margin-left: 5px">秒</span>
            </div>

            <div class="script-body-item" style="width: 34%; margin-left: 30px">
              <el-input :ref="`goods_num${index}`" style="width: 130px" v-model="item.list.goodsNum" @input="showShopTime(index)" placeholder="设置选品数量"></el-input>
              <span style="margin-left: 5px">个</span>
            </div>
            <div class="script-body-item" style="width: 25%">
              <el-link :underline="false" style="color: red; cursor: pointer" @click="delShopList(index)">删除</el-link>
            </div>
          </div>
        </div>
      </div>

      <!-- 福袋 -->
      <div class="baglist" v-if="item.type == 3">
        <div class="script-container" v-for="(subitem, subindex) in item.list" :key="subindex">
          <div class="script-header">
            <div class="script-header-item" style="width: 9%; padding-left: 39px">脚本类型</div>
            <div class="script-header-item" style="width: 29%">时间</div>
            <div class="script-header-item" style="width: 11%">福袋名称</div>
            <div class="script-header-item" style="width: 8.5%">倒计时</div>
            <div class="script-header-item" style="width: 10.5%">可中奖人</div>
            <div class="script-header-item" style="width: 14.5%">参与方式</div>
            <div class="script-header-item" style="width: 17%">参与口令</div>
            <div class="script-header-item" style="width: 3%">操作</div>
          </div>
          <div class="script-body">
            <div class="script-body-item" style="width: 9%; border-right: 3px solid #f7f8fc; font-weight: bold; padding-left: 39px; box-sizing: border-box">福袋</div>
            <div class="script-body-item" style="width: 30%">
              <el-input-number :controls="false" style="width: 50px; margin-left: 39px" v-model="subitem.time1" :ref="`bag_start${index}_${subindex}`"></el-input-number>
              <span style="margin-left: 5px">分</span>
              <el-input-number :controls="false" style="width: 50px; margin-left: 5px" v-model.number="subitem.time2" @blur="getBagEndTime(index, subindex, item, subitem)" :ref="`bag_end${index}_${subindex}`" :max="59"> </el-input-number>
              <span style="margin-left: 5px">秒 ~</span>
              <el-input disabled style="width: 50px; margin-left: 5px" v-model="subitem.time3"></el-input>
              <span style="margin-left: 5px">分</span>
              <el-input disabled style="width: 50px; margin-left: 5px" v-model="subitem.time4"></el-input>
              <span style="margin-left: 5px">秒</span>
            </div>
            <div class="script-body-item" style="width: 11%">
              {{ subitem.bag_name }}
            </div>
            <div class="script-body-item" style="width: 9%">
              <span>{{ Math.floor(subitem.countdown / 60) }}分{{ Math.floor(subitem.countdown % 60) }}秒</span>
            </div>
            <div class="script-body-item" style="width: 9%; text-align: center">
              <span>{{ subitem.winning_number }}人</span>
            </div>
            <div class="script-body-item" style="width: 13%">
              <span>{{ subitem.in_type == 1 ? "所有观众" : "仅限粉丝" }}</span>
            </div>
            <div class="script-body-item" style="width: 17%">
              <span>{{ subitem.command_id == 1
                  ? "关注加粉丝团领取超值红包"
                  : subitem.command_id == 2
                    ? "分享直播间抢红包"
                    : subitem.command_id == 3
                      ? "点赞加关注领取超值福袋"
                      : subitem.command_id == 4
                        ? "双击小红心领取超值大礼包"
                        : subitem.command_id == 5
                          ? "想要请扣6666"
                          : "",
              }}</span>
            </div>
            <div class="script-body-item" style="width: 3%">
              <el-link :underline="false" style="color: red; cursor: pointer" @click="delBagList(subitem.bag_id, index, subindex)">删除</el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="getBack">
      <el-button class="getBackBtn" type="primary" @click="backTo">上一步</el-button>
      <el-button class="getBackBtn" type="primary" @click="backNext">下一步</el-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { bagList, getBagInfo } from "@/utils/apis";
export default {
  name: "NewAdvertisingPlanSuccess",
  data() {
    return {
      window: false, //会话框显示
      array: [], //存放话术，商品，福袋数据
      scriptOptions: [
        {
          value: "选项1",
          label: "话术",
          id: 1,
        },
        {
          value: "选项2",
          label: "商品",
          id: 2,
        },
        {
          value: "选项3",
          label: "福袋",
          id: 3,
        },
      ], //脚本类型下拉框数据
      bagListCategory: [], //福袋分类下拉框数据
      scriptCategory: "", //脚本类型选中值
      bagListChecked: [], //选择福袋选中值
      bagCheckList: [], //福袋多选框数据
      bagItem: "全部", //福袋分类下拉框选中的值
      isadd: 0, //控制商品数据只添加一条
      startId: 0, //每添加一条话术自增id
      liveTimeTotal: void 0,
      editData: "",
      barrageArr: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.editData = JSON.parse(localStorage.getItem("editData"));
      this.editScriptData();
    }
  },
  methods: {
    closeWindow() {
      this.scriptCategory = "";
      this.window = false;
      let arr = [];
      this.array.forEach((item) => {
        if (item.type == 3) {
          item.list.forEach((subItem) => {
            arr.push(this.bagListChecked.find((ele) => ele == subItem.bag_id));
          });
        }
      });
      this.bagListChecked = arr;
    },
    // 添加脚本类型
    addDataFormTable() {
      switch (this.scriptCategory) {
        case 1:
          this.array.push({
            type: 1,
            list: [
              {
                id: this.startId++,
                minute: "",
                seconds: "",
                script_content: "",
                key_word: "",
                miss_key_word: "",
                turnover_rate: void 0,
                barrage: [],
                level: "",
              },
            ],
          });
          this.array.forEach((item, index) => {
            if (item.type == 1) {
              item.list.map((subItem) => {
                subItem.level = index;
                return subItem;
              });
            }
          });
          break;
        case 2:
          if (this.isadd == 0) {
            this.array.push({
              type: 2,
              list: {
                time1: 0,
                time2: 0,
                time3: 0,
                time4: 0,
                goodsNum: void 0,
                startTime: "",
                start_time: "",
                endTime: "",
              },
            });
            this.isadd = 1;
          } else {
            this.window = false;
            return false;
          }
          break;
        case 3:
          let list = this.bagCheckList.reduce((acc, cur) => {
            if (this.bagListChecked.find((ele) => ele == cur.id)) {
              acc.push({
                bag_id: cur.id,
                time1: 0,
                time2: 0,
                time3: 0,
                time4: 0,
                bag_name: cur.bag_name,
                countdown: cur.countdown,
                in_type: cur.in_type,
                command_id: cur.command_id,
                winning_number: cur.winning_number,
              });
            }
            return acc;
          }, []);
          if (this.array.length == 0) {
            this.array.push({
              type: 3,
              list,
            });
          } else {
            let arr = this.array.filter((item) => {
              return item.type == 3;
            });
            if (arr.length == 0) {
              this.array.push({
                type: 3,
                list,
              });
            } else {
              let oldBag = [];
              // 取出所有type为3的福袋id
              arr.forEach((item, index) => {
                item.list.forEach((subItem, subIndex) => {
                  oldBag.push(subItem.bag_id);
                });
              });
              //减少
              if (oldBag.length > this.bagListChecked.length) {
                oldBag.forEach((item) => {
                  if (this.bagListChecked.indexOf(item) == -1) {
                    let bagIndex = [];
                    this.array.forEach((arrItem, arrIndex) => {
                      if (arrItem.type == 3) {
                        arrItem.list.forEach((subItem, subIndex) => {
                          if (subItem.bag_id == item) {
                            bagIndex = [arrIndex, subIndex];
                          }
                        });
                      }
                    });
                    let len = this.array[bagIndex[0]].list.length;
                    if (len > 1) {
                      this.array[bagIndex[0]].list.splice(bagIndex[1], 1);
                    } else {
                      this.array.splice(bagIndex[0], 1);
                    }
                  }
                });
              } else {
                this.bagListChecked.forEach((item) => {
                  if (oldBag.indexOf(item) == -1) {
                    let bagInfo = this.bagCheckList.find((bagItem) => {
                      return bagItem.id == item;
                    });
                    let newObj = {
                      bag_id: bagInfo.id,
                      time1: 0,
                      time2: 0,
                      time3: 0,
                      time4: 0,
                      bag_name: bagInfo.bag_name,
                      countdown: bagInfo.countdown,
                      in_type: bagInfo.in_type,
                      command_id: bagInfo.command_id,
                      winning_number: bagInfo.winning_number,
                      start_time: void 0,
                      end_time: void 0,
                    };
                    let newList = [];
                    newList.push(newObj);
                    this.array.push({
                      type: 3,
                      list: newList,
                    });
                  }
                });
              }
            }
          }
      }
      this.window = false;
    },
    // 添加脚本话术
    addStartScript(subindex) {
      this.array.forEach((item, index) => {
        if (index == subindex) {
          let obj = {
            id: this.startId++,
            minute: "",
            seconds: "",
            script_content: "",
            key_word: "",
            miss_key_word: "",
            turnover_rate: void 0,
            barrage: [],
            level: index,
          };
          item.list.push(obj);
        }
      });
    },
    // 删除整个话术
    delScriptBox(index) {
      this.$confirm("删除后整个脚本类型包含所有话术会一起删除, 您确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.array.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加话术弹幕
    addScriptItem(row, sindex) {
      this.array[sindex].list.forEach((item) => {
        if (item.id == row) {
          item.barrage.push({
            content: "",
            number: 0,
          });
        }
      });
    },
    // 删除开场弹幕
    delStartSubItem(subIndex, index, subIndexs) {
      this.array[index].list[subIndex].barrage.splice(subIndexs, 1);
      this.$message({
        type: "success",
        message: `删除成功`,
      });
    },
    // 删除话术中的某条话术
    delScript(index, subindex) {
      this.array[index].list.splice(subindex, 1);
      this.$message({
        type: "success",
        message: "删除成功",
      });
    },
    // 删除商品选品列表
    delShopList(index) {
      this.array.splice(index, 1);
      this.$message({
        type: "success",
        message: "删除成功",
      });
      this.isadd = 0;
    },
    // 选择脚本类型为福袋，显示福袋分类,并将数据同时渲染到福袋列表中
    showBagListData(category) {
      if (category == 3) {
        let prams = {
          limit: "all",
        };
        bagList(prams).then((res) => {
          if (res.code === 200) {
            let obj = {
              category_id: "全部",
              id: "全部",
              name: "全部",
              bag_name: "全部",
            };
            this.bagListCategory = JSON.parse(JSON.stringify(res.data)); //福袋
            this.bagCheckList = JSON.parse(JSON.stringify(res.data));
            this.bagListCategory.unshift(obj);
          }
        });
      }
    },
    // 选择福袋分类显示福袋列表数据
    getBagItemShowbagList(item) {
      if (item == "全部") {
        let params = {
          limit: "all",
        };
        this.getBagCateList(params);
      } else {
        let params = {
          limit: "all",
          category_id: item,
        };
        this.getBagCateList(params);
      }
    },
    // 请求福袋
    getBagCateList(params) {
      bagList(params).then((res) => {
        if (res.code === 200) {
          this.bagCheckList = res.data;
        }
      });
    },
    // 删除福袋列表
    delBagList(id, index, subIndex) {
      if (this.array[index].list.length > 1) {
        this.array[index].list.splice(subIndex, 1);
      } else {
        this.array.splice(index, 1);
      }
      this.bagListChecked = this.bagListChecked.filter((item) => item !== id);
    },
    // 填写选品数量自动生成商品开始结束时间
    showShopTime(index) {
      let shopItem = this.array[index].list;
      console.log(shopItem);
      if (shopItem.goodsNum > JSON.parse(localStorage.getItem("twoForm")).length) {
        this.$message({
          type: "error",
          message: "请输入选品数量小于上一页选择的商品数量,并且不能为0",
        });
      } else {
        let shopTime = JSON.parse(localStorage.getItem("shopTime"));
        let seconds = (Number(shopItem.goodsNum) * Number(shopTime.second)) % 60;
        let moreM = parseInt((Number(shopItem.goodsNum) * Number(shopTime.second)) / 60);
        let minute = Number(shopTime.minute) * Number(shopItem.goodsNum) + moreM;
        shopItem.start_time = Number(shopItem.time1) * 60 + Number(shopItem.time2);

        //   if (minute * 60 + seconds < shopItem.start_time) {
        //   console.log(minute * 60 + seconds)
        //   console.log(shopItem.start_time)
        //   this.$message({
        //     type: "warning",
        //     message: "选品结束时间必须大于开始时间",
        //   });
        //   shopItem.time3 = shopItem.time4 = "";
        // } else
        if (minute * 60 + seconds == 0) {
          this.$message({
            type: "warning",
            message: "设置的选品数量必须大于0",
          });
          shopItem.time3 = shopItem.time4 = "";
        } else {
          let end = seconds + shopItem.time2;
          if (end >= 60) {
            shopItem.time3 = minute + shopItem.time1 + 1;
            shopItem.time4 = end > 60 ? Math.floor(end % 60) : end == 60 ? 0 : 0;
          } else {
            shopItem.time3 = minute + shopItem.time1;
            shopItem.time4 = seconds + shopItem.time2;
          }

          shopItem.end_time = Number(shopItem.time3) * 60 + Number(shopItem.time4);
        }
      }
    },
    // 填写福袋开始时间计算福袋结束时间
    getBagEndTime(index, subindex, item, subitem) {
      let time = 0;
      getBagInfo(subitem.bag_id).then((res) => {
        if (res.code === 200) {
          res.data.getExtendData.forEach((item) => {
            time += Number(item.answer_min) * 60 + Number(item.answer_sec);
          });
          let bagItem = this.array[index].list[subindex];
          bagItem.time3 = parseInt((Number(bagItem.time1) * 60 + Number(bagItem.time2) + time) / 60);
          bagItem.time4 = parseInt((Number(bagItem.time1) * 60 + Number(bagItem.time2) + time) % 60);
          this.$forceUpdate();
          this.checkedStatus(index, item, subindex);
        }
      });
    },
    // 计算时间差
    difference() {
      const copy = JSON.parse(JSON.stringify(this.array));
      let total = 0;
      const r = copy.reduce((acc, cur) => {
        let target = [];
        switch (cur.type) {
          case 1:
            target = cur.list.map((item) => `${item.minute}:${item.seconds}`);
            break;
          case 2:
            target = [`${cur.list.time1}:${cur.list.time2}`, `${cur.list.time3}:${cur.list.time4}`];
            break;
          case 3:
            target = cur.list.reduce((cAcc, cCur) => {
              return cAcc.concat([`${cCur.time1}:${cCur.time2}`, `${cCur.time3}:${cCur.time4}`]);
            }, []);
            break;
        }
        acc = acc.concat(target);
        return acc;
      }, []);
      for (let i = 0; i < r.length; i++) {
        if (i === 0) continue;
        total = r[i].split(":")[0] * 60 + +r[i].split(":")[1];
        // total +=
        // r[i].split(":")[0] * 60 +
        // +r[i].split(":")[1]
        // -
        // (r[i - 1].split(":")[0] * 60 + +r[i - 1].split(":")[1]);
      }
      let ms = {
        minute: Math.floor(total / 60),
        second: Math.floor(total % 60),
      };
      localStorage.setItem("liveTotal", JSON.stringify(ms));
      this.liveTimeTotal = total;
    },
    // 返回上一步
    backTo() {
      if (this.$route.query.id) {
        this.$router.push({
          path: "/scriptManagement/newScriptManagement/two",
          query: { id: this.$route.query.id },
        });
      } else {
        this.$router.push({
          path: "/scriptManagement/newScriptManagement/two",
        });
      }
    },
    // 验证必填项
    checkStatus() {
      let isErrMinute = 0,
        isGoods = 0,
        isBag = 0;
      let time = [];
      this.array.forEach((item, index) => {
        if (item.type == 1) {
          item.list.forEach((subItem, subIndex) => {
            if (subItem.minute.length == 0) {
              isErrMinute = 1;
              this.$refs[`speech_minute${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`speech_minute${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (subItem.seconds.length == 0) {
              isErrMinute = 1;
              this.$refs[`speech_seconds${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`speech_seconds${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (!subItem.script_content) {
              isErrMinute = 1;
              this.$refs[`speech_content${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`speech_content${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (!subItem.key_word) {
              isErrMinute = 1;
              this.$refs[`speech_key${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`speech_key${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (!subItem.miss_key_word) {
              isErrMinute = 1;
              this.$refs[`speech_miss${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`speech_miss${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (!subItem.turnover_rate) {
              isErrMinute = 1;
              this.$refs[`speech_rate${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`speech_rate${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (subItem.barrage) {
              subItem.barrage.forEach((cItem, cIndex) => {
                if (!cItem.content) {
                  isErrMinute = 1;
                  this.$refs[`children_content${subIndex}_${cIndex}`][0].$el.classList.add("isError");
                } else {
                  this.$refs[`children_content${subIndex}_${cIndex}`][0].$el.classList.remove("isError");
                }
                if (!cItem.number) {
                  isErrMinute = 1;
                  this.$refs[`children_number${subIndex}_${cIndex}`][0].$el.classList.add("isError");
                } else {
                  this.$refs[`children_number${subIndex}_${cIndex}`][0].$el.classList.remove("isError");
                }
              });
            }
            time.push(subItem.minute * 60 + subItem.seconds);
          });
        }
        if (item.type == 2) {
          if (!item.list.time1 && item.list.time1.length == 0) {
            isGoods = 1;
            this.$refs[`goods_start${index}`][0].$el.classList.add("isError");
          } else {
            this.$refs[`goods_start${index}`][0].$el.classList.remove("isError");
          }
          if (!item.list.time2 && item.list.time2.length == 0) {
            isGoods = 1;
            this.$refs[`goods_end${index}`][0].$el.classList.add("isError");
          } else {
            this.$refs[`goods_end${index}`][0].$el.classList.remove("isError");
          }
          if (!item.list.goodsNum) {
            isGoods = 1;
            this.$refs[`goods_num${index}`][0].$el.classList.add("isError");
          } else {
            this.$refs[`goods_num${index}`][0].$el.classList.remove("isError");
          }
          time.push(Number(item.list.time1) * 60 + Number(item.list.time2), Number(item.list.time3) * 60 + Number(item.list.time4));
        }
        if (item.type == 3) {
          item.list.forEach((subItem, subIndex) => {
            if (!subItem.time1 && subItem.time1.length == 0) {
              isBag = 1;
              this.$refs[`bag_start${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`bag_start${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            if (!subItem.time2 && subItem.time2.length == 0) {
              isBag = 1;
              this.$refs[`bag_end${index}_${subIndex}`][0].$el.classList.add("isError");
            } else {
              this.$refs[`bag_end${index}_${subIndex}`][0].$el.classList.remove("isError");
            }
            time.push(Number(subItem.time1) * 60 + Number(subItem.time2), Number(subItem.time3) * 60 + Number(subItem.time4));
          });
        }
      });
      if (isErrMinute == 1) {
        this.$message({
          type: "warning",
          message: "每项为必填",
          duration: 1000,
        });
        return false;
      }
      if (isGoods == 1) {
        this.$message({
          type: "warning",
          message: "每项为必填",
          duration: 1000,
        });
        return false;
      }
      if (isBag == 1) {
        this.$message({
          type: "warning",
          message: "每项为必填",
          duration: 1000,
        });
        return false;
      }
      for (let i = 0; i < time.length - 1; i++) {
        if (time[i] >= time[i + 1]) {
          this.$message({
            type: "error",
            message: "时间段有误！请检查时间是否填写正确",
            duration: 2000,
          });
          return false;
        }
      }
      return true;
    },
    // 判断时间段
    checkedStatus(index, item, subIndex) {
      const getScriptSecond = (target) => target.minute * 60 + target.seconds * 1;
      const getGoodsAndBagSecond = (target) => [target.time1 * 60 + target.time2 * 1, target.time3 * 60 + target.time4 * 1];
      const getSecond = [getScriptSecond, getGoodsAndBagSecond, getGoodsAndBagSecond];
      let secondExist;
      if (index <= 0 && !subIndex) {
        return false;
      }
      if (item.type == 1) {
        // secondExist = item.list.slice(-1)[0].seconds;
        secondExist = item.list[subIndex].seconds;
      } else {
        // secondExist = item.type == 2 ? item.list.time2 : item.list.slice(-1)[0].time2;
        secondExist = item.type == 2 ? item.list.time2 : item.list[subIndex].time2;
      }
      const keys = [
        ["minute", "seconds"],
        ["time1", "time2"],
        ["time1", "time2"],
        ["time3", "time4"],
      ][item.type - 1];
      if ((index > 0 && secondExist) || subIndex <= 0) {
        const { type: beforeType, list: beforeList } = this.array[index - 1];
        const beforeTarget = beforeType == 2 ? beforeList : beforeList.slice(-1)[0];
        const selfTarget = item.type == 2 ? item.list : item.list[subIndex];
        const beforeSecond = beforeType != 1 ? getSecond[beforeType - 1](beforeTarget)[1] : getSecond[beforeType - 1](beforeTarget);

        const selfSecond = item.type != 1 ? getSecond[item.type - 1](selfTarget)[0] : getSecond[item.type - 1](selfTarget);
        if (beforeSecond >= selfSecond) {
          if (this.$route.query.id) {
            this.$message({
              type: "error",
              message: "请输入当前时间需要大于上个时间段",
            });
            this.$set(selfTarget, keys[0], "");
            this.$set(selfTarget, keys[1], "");
            this.$forceUpdate();
          } else {
            this.$message({
              type: "error",
              message: "请输入当前时间需要大于上个时间段",
            });
            this.$nextTick(() => {
              this.$set(selfTarget, keys[0], "");
              this.$set(selfTarget, keys[1], "");
            });
          }
        }
      } else if ((item.type == 1 || item.type == 3) && subIndex > 0) {
        const beforeSecond = item.type == 1 ? getSecond[item.type - 1](item.list[subIndex - 1]) : getSecond[item.type - 1](item.list[subIndex - 1])[1];

        const selfSecond = item.type == 1 ? getSecond[item.type - 1](item.list[subIndex]) : getSecond[item.type - 1](item.list[subIndex])[0];
        if (beforeSecond >= selfSecond) {
          // 如果是编辑就不清空
          if (this.$route.query.id) {
            this.$message({
              type: "error",
              message: "该时间小于上一段时间",
            });
            this.$set(item.list[subIndex], keys[0], "");
            this.$set(item.list[subIndex], keys[1], "");
            this.$set(item.list[subIndex], keys[2], "");
            this.$forceUpdate();
          } else {
            this.$message({
              type: "error",
              message: "该时间小于上一段时间",
            });
            this.$nextTick(() => {
              this.$set(item.list[subIndex], keys[0], "");
              this.$set(item.list[subIndex], keys[1], "");
              this.$set(item.list[subIndex], keys[2], "");
            });
          }
        }
      }
    },
    // 下一步
    backNext() {
      if (this.array.length !== 0) {
        let res = this.checkStatus();
        if (res) {
          let goodNum = JSON.parse(localStorage.getItem("twoForm"));
          let res = this.array.filter((item) => {
            return item.type == 2;
          });
          if (res.length > 0) {
            if (res[0].list.goodsNum > 0 && res[0].list.goodsNum <= goodNum.length) {
              this.array.forEach((item, index) => {
                switch (item.type) {
                  case 1:
                    if (index > 0) {
                      if (this.array[index - 1].type == 1) {
                        let res = this.array[index - 1].list.slice(-1)[0].minute * 60 + this.array[index - 1].list.slice(-1)[0].seconds;
                        item.list.map((subItem, subIndex) => {
                          if (subIndex > 0) {
                            subItem.start_time = item.list[subIndex - 1].minute * 60 + item.list[subIndex - 1].seconds;
                          } else {
                            subItem.start_time = res;
                          }
                          return subItem;
                        });
                      } else if (this.array[index - 1].type == 2) {
                        let res = this.array[index - 1].list.time3 * 60 + this.array[index - 1].list.time4 + 1;
                        item.list.map((subItem, subIndex) => {
                          if (subIndex > 0) {
                            subItem.start_time = item.list[subIndex - 1].minute * 60 + item.list[subIndex - 1].seconds + 1;
                          } else {
                            subItem.start_time = res;
                          }
                          return subItem;
                        });
                      } else if (this.array[index - 1].type == 3) {
                        let res = this.array[index - 1].list.slice(-1)[0].time3 * 60 + this.array[index - 1].list.slice(-1)[0].time4;
                        item.list.map((subItem, subIndex) => {
                          if (subIndex > 0) {
                            subItem.start_time = item.list[subIndex - 1].minute * 60 + item.list[subIndex - 1].seconds + 1;
                          } else {
                            subItem.start_time = res;
                          }
                          return subItem;
                        });
                      }
                    } else {
                      item.list.map((subItem, subIndex) => {
                        if (subIndex > 0) {
                          subItem.start_time = item.list[subIndex - 1].minute * 60 + item.list[subIndex - 1].seconds;
                        } else {
                          subItem.start_time = 0;
                        }
                        return subItem;
                      });
                    }
                    break;
                  case 3:
                    item.list.map((subItem) => {
                      subItem.start_time = subItem.time1 * 60 + +subItem.time2;
                      subItem.end_time = subItem.time3 * 60 + +subItem.time4;
                      return subItem;
                    });
                    break;
                }
              });
              localStorage.setItem("threeForm", JSON.stringify(this.array));
              this.difference();
              if (this.liveTimeTotal > 3600) {
                this.$message({
                  type: "error",
                  message: "脚本总时长请勿超过60分钟",
                });
              } else {
                if (this.$route.query.id) {
                  this.$router.push({
                    path: "/scriptManagement/newScriptManagement/four",
                    query: { id: this.$route.query.id },
                  });
                } else {
                  this.$router.push({
                    path: "/scriptManagement/newScriptManagement/four",
                    query: { id: this.$route.query.id },
                  });
                }
              }
            } else {
              this.$message({
                type: "error",
                message: "选品数量必须小于或等于商品本身数量，并且不能为0",
              });
              return false;
            }
          } else {
            this.$message({
              type: "error",
              message: "请先添加脚本类型里面的商品！",
            });
            return false;
          }
        }
      } else {
        this.$message({ type: "error", message: "请添加脚本" });
        return false;
      }
    },
    // 数据回显
    editScriptData() {
      const keys = ["getWord", "getGoodsSetting", "getBag"];
      const { editData } = this;
      const typeList = [];
      //获取所有信息数组并排序
      let allInfos = keys
        .reduce((sum, item, index) => {
          editData[item].forEach((addItem) => (addItem.type = index + 1));
          return sum.concat(...editData[item]);
        }, [])
        .sort((a, b) => a.start_time - b.start_time);
      /**脚本替换数组 { index, list }*/
      const replaces = [];
      //处理脚本是否同级
      allInfos.forEach((item, index) => {
        const exists = replaces.reduce((sum, existItem) => sum.concat(existItem.list), []);
        if (item.type == 1 && !exists.some((existItem) => existItem.id == item.id)) {
          let temp = {};
          const targets = allInfos.filter((allItem) => allItem.type == 1 && item.level == allItem.level && item.id != allItem.id);
          temp.index = index;
          temp.list = [item];
          targets.length > 0 && temp.list.push(...targets);
          replaces.push(temp);
        } else if (item.type != 1) {
          let { start_time, end_time } = item;
          const times = [Math.floor(start_time / 60), start_time % 60, Math.floor(end_time / 60), end_time % 60];
          times.forEach((timeItem, index) => (item[`time${index + 1}`] = timeItem));
          item.type == 2 && this.$set(item, "goodsNum", item.goods_number);
        }
      });
      //进行替换操作
      replaces.forEach((item) => (allInfos[item.index] = item.list));
      //过滤多余脚本对象
      allInfos = allInfos.filter((item) => (item.type && item.type != 1) || !item.type);
      //包装数组为前端需要的数据结构
      allInfos.forEach((item, index) => {
        let temp = item.type == 2 || item.type === undefined ? item : [item];
        let type = item.type ? item.type : 1;
        typeList[index] = { type, list: temp };
      });
      typeList.forEach((item) => {
        if (item.type == 1) {
          item.list.forEach((subItem) => {
            !subItem.barrage ? (subItem.barrage = []) : "";
          });
        }
      });
      // this.$set(this, "array", typeList)
      this.array = typeList;
      // this.array.push(...typeList)
      const tickBag = allInfos.filter((item) => item.type == 3).map((item) => item.bag_id);
      this.bagListChecked = tickBag;
      this.isadd = 1;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.new-script-managment {
  padding-bottom: 30px;

  .btn-two {
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .no-data-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #a8a8b8;
    margin-bottom: 30px;
  }

  .window-open {
    .header-tag-box {
      display: inline-block;
      width: 4px;
      height: 12px;
      margin-right: 10px;
      background-color: #2821fc;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #061101;
    }

    .dialog-footer {
      display: flex;
      justify-content: center;
    }

    .shop-table {
      margin: 15px 35px 0 35px;
      border: 1px solid rgb(233, 232, 232);
      width: auto;
      height: 400px;
      overflow-y: auto;
      border-radius: 5px;

      .shop-check {
        display: flex;
        align-items: center;
        margin: 20px;
      }
    }
  }

  .script-container {
    margin-top: 29px;
    height: auto;
    background: #ffffff;
    border-radius: 2px;

    .script-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
      box-sizing: border-box;
    }

    .script-bory-box {
      height: 300px;
      overflow-y: auto;

      .script-body {
        display: flex;
        border: 2px solid #f7f8fc;
        width: auto;

        .script-body-item {
          height: auto;
          padding: 20px 0;
          display: flex;
          align-items: center;
        }

        .script-body-icon {
          height: 60px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: 35px;

          .lrline {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-left: 2px solid #eee;
            border-bottom: 2px solid #eee;
          }
        }
      }
    }

    .script-footer {
      display: flex;
      border: 2px solid #f7f8fc;
      height: 55px;
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;
    }
  }

  .script-container {
    margin-top: 29px;
    margin-right: 30px;
    height: 1%;
    background: #ffffff;
    border: 2px solid #f7f8fc;
    border-radius: 2px;

    .script-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
      box-sizing: border-box;
    }

    .script-body {
      display: flex;

      .script-body-item {
        display: flex;
        align-items: center;
        height: 64px;
      }
    }
  }

  .getBack {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
